import $ from 'jquery';

const WrapDateFields = () => {
  $(document).on('turbolinks:load', () => {
    var dob = null;

    if ($('#member_date_of_birth').length > 0) {
      dob = $('#member_date_of_birth').val();
    }

    $('.date-time')
      .find('select')
      .each(function(index, item) {
        if (dob) {
          var idName = $(item)[0].id;
          if (idName.endsWith('1i')) {
            var year = dob.split('-')[0];
            $(item).val(year);
          } else if (idName.endsWith('2i')) {
            var month = dob.split('-')[1].split('0')[1];
            $(item).val(month);
          } else if (idName.endsWith('3i')) {
            var day = dob.split('-')[2].split('0')[1];
            $(item).val(day);
          }
        }

        if (item.disabled) {
          $(item).wrapAll('<div class="select-div disabled">');
        } else {
          if ($(item).closest('div.select-div').length === 0) {
            $(item).wrapAll('<div class="select-div">');
          }
        }
      });
  });
};

export default WrapDateFields;

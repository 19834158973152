import $ from 'jquery';

const ChoirHelper = () => {
  $(document).on('click', '.request-change', event => {
    console.log('Change');
    $('#choir_update_status').val('change');
  });

  $(document).on('click', '.choir-update', event => {
    //console.log($(event.target).classList);

    var status = 'change';

    if ($(event.target).hasClass('confirm')) {
      status = 'confirm';
    }

    console.log(status);
    $(event.target)
      .closest('form')
      .find('#choir_update_status')
      .val(status);
  });
};

export default ChoirHelper;

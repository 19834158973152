import $ from 'jquery';

const SettingsHelper = () => {
  $(document).on('click', '.setting-help', event => {
    event.preventDefault();
    $('.explainer').removeClass('hidden');
    $('.setting-help').prop('disabled', true);

    $('html,body').animate({
      scrollTop: ($('body').position().top += 1300)
    });
  });
};

export default SettingsHelper;

import $ from 'jquery';
import translate from 'i18n';

const ValidateLocation = () => {
  $(document).on('keyup change', '.location-form input', event => {
    validateNewLength(event.target);
    setLocationId(event.target);
  });

  const validateNewLength = target => {
    var minLength = 0;
    var name = $('#location_name').val();
    var positionA = $('#location_position_a').val();
    if (
      name &&
      name.length > minLength &&
      positionA &&
      positionA.length > minLength
    ) {
      $('.location-form')
        .find('.falert')
        .hide();
      $('input.location').prop('disabled', false);
    } else {
      var translatedText = translate('errors.location.pos_a_required');
      $('.location-form').find('.falert')[0].textContent = translatedText;
      $('.location-form')
        .find('.falert')
        .show();
    }
  };

  const setLocationId = target => {
    if (
      $.inArray(target.id, [
        'location_position_a',
        'location_position_b',
        'location_position_c'
      ]) >= 0
    ) {
      var positionA = $('#location_position_a').val();
      var positionB = $('#location_position_b').val();
      var positionC = $('#location_position_c').val();
      $('.location-id').text(positionA + positionB + positionC);
      $('#location_name').val(positionA + positionB + positionC);
    }
  };
};

export default ValidateLocation;

import $ from 'jquery';

const ProjectTasks = () => {
  $(document).on('turbolinks:load', event => {
    $('.service-form .checkbox-holder .checkbox input').each(function(
      index,
      $checkbox
    ) {
      resetInput($checkbox);
    });

    $('.project-form .tasks .checkbox-holder .checkbox input').each(function(
      index,
      $checkbox
    ) {
      resetInput($checkbox);
    });

    $('.task-form .services .checkbox-holder .checkbox input').each(function(
      index,
      $checkbox
    ) {
      resetInput($checkbox);
    });

    $('.task-group-form .tasks .checkbox-holder .checkbox input').each(function(
      index,
      $checkbox
    ) {
      resetInput($checkbox);
    });
  });

  /*
   *  Sending the generated page values combined with the updated values below
   *  results in invalid or incomplete post params
   *  There's probably a better fix, this is a workaround for the moment
   */
  const resetInput = $checkbox => {
    var parent = $checkbox.closest('.checkbox-holder');
    var $hiddenInput = $(parent).find("input[type='hidden']");
    var id = 'link_' + $(parent).find("input[type='checkbox']")[0].id;
    $hiddenInput[0].id = id;
    $hiddenInput[0].name = '';
    $hiddenInput[0].value = '';
    $checkbox.name = '';
    $checkbox.value = '';
  };

  $(document).on('click', '.service-form .checkbox-holder .checkbox', event => {
    event.preventDefault();
    toggleCheckedInList($(event.target), 'service_task');
  });

  $(document).on(
    'click',
    '.project-form .tasks .checkbox-holder .checkbox',
    event => {
      event.preventDefault();

      // TODO PROJECT TEAMS
      toggleCheckedInList($(event.target), 'project_task');
    }
  );

  $(document).on('click', '.project-invoice-id .checkbox', event => {
    event.preventDefault();

    const $parent = $(event.target).closest('.checkbox-holder');
    const $hiddenInput = $parent.find("input[type='hidden']");
    var invoiceable = toggleChecked($parent, $hiddenInput);
    $('#invoiceable').val(invoiceable);
  });

  $(document).on(
    'click',
    '.task-form .services .checkbox-holder .checkbox',
    event => {
      event.preventDefault();
      var type = $(event.target).closest('.checkbox-holder')[0].classList[2];
      toggleCheckedInList($(event.target), 'task_' + type);
    }
  );

  $(document).on(
    'click',
    '.task-template .checkbox-holder .checkbox',
    event => {
      event.preventDefault();
      const $parent = $(event.target).closest('.checkbox-holder');
      const $hiddenInput = $parent.find("input[type='hidden']");
      var setTrue = toggleChecked($parent, $hiddenInput);
      if (setTrue === '1') {
        $('.task-template .warning').show();
      } else {
        $('.task-template .warning').hide();
      }
    }
  );

  $(document).on(
    'click',
    '.task-group-form .checkbox-holder .checkbox',
    event => {
      event.preventDefault();
      var type = $(event.target).closest('.checkbox-holder')[0].classList[2];
      toggleCheckedInList($(event.target), 'task_group_task');
    }
  );

  /* Service (aka template) id saved in a hidden field */
  $(document).on('click', '.tasks .create-task', event => {
    var serviceId = $('.hidden-service-id').val();
    /* Blank is expected if there is no filter applied */
    if (serviceId && serviceId > 0) {
      var $form = $('.task-form').first();
      var $target = $form.find('input#' + serviceId).closest('label.checkbox');
      /* Automatically check the service that matches the filter */
      toggleCheckedInList($target, 'task_service');
    }
  });

  const toggleChecked = ($parent, $hiddenInput) => {
    var value = '1';
    if ($parent.hasClass('checked')) {
      value = '0';
    }

    $hiddenInput[0].value = value;
    $hiddenInput.prop('checked', !$hiddenInput.prop('checked'));
    $parent.toggleClass('checked');

    return value;
  };

  // When the checkbox is in a long list of ids, manage the id and name fields directly.
  // There may be a better way to do this, but it works. Optimisations welcome.
  const updateIds = ($target, type, $hiddenInput) => {
    var id = $target.find('input')[0].id;

    var shortId = id;
    var idName = type.replace('_', '[') + '_ids][' + id + ']';
    if (type.startsWith('task_group')) {
      idName = 'task_group[task_ids][' + id + ']';
    }

    if (id.indexOf('_') > 0) {
      shortId = id.split('_')[1];
      idName = type.replace('_', '[') + '_ids][' + shortId + ']';
    } else {
      shortId = id + '_ids_' + id;
    }

    $hiddenInput[0].id = shortId;
    $hiddenInput[0].name = idName;
  };

  const toggleCheckedInList = ($target, type) => {
    var $parent = $target.closest('.checkbox-holder');
    const $hiddenInput = $parent.find("input[type='hidden']");

    updateIds($target, type, $hiddenInput);

    toggleChecked($parent, $hiddenInput);
  };
};

export default ProjectTasks;

import $ from 'jquery';
import { updateFinishForHours } from './booking_date_utils';
import { validateDate } from 'date_utils';
import validateHours from 'validate_hours';
import { clearErrorAndWarn } from 'utils';

const getBookingTaskInfo = taskId => {
  var url = '/tasks/' + taskId;

  $.ajax({
    url: url,
    dataType: 'json',
    success: function(response) {
      $('#booking_description').val(response.int_description);

      var hours = 0;

      if (response.hours === null) {
        $('#booking_hours').val('');
      } else {
        hours = parseFloat(response.hours);
        $('#booking_hours').val(hours);
        clearErrorAndWarn();
      }

      validateHours();
    }
  });
};

export default getBookingTaskInfo;

import $ from 'jquery';
import translate from 'i18n';

const setWarn = key => {
  $('.falert').hide();
  $('.pane .warning')[0].textContent = translate(key);
  $('.pane .warning').show();
  $('.booking').prop('disabled', false);
};

export default setWarn;

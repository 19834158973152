import $ from 'jquery';

const Apps = () => {
  $(document).on('turbolinks:load', event => {
    if ($('.panel.regular').length === 0) {
      return;
    }

    $('.panel.regular .actions .btn').addClass('disabled');
  });

  $(document).on('change', '.panel.regular select', event => {
    $('.panel.regular .actions .btn').removeClass('disabled');
  });

  $(document).on('click', '.password-toggle', event => {
    const passwordField = $(event.target)
      .closest('.app-field')
      .find('input')[0];
    if (passwordField.type == 'password') {
      passwordField.type = 'text';
    } else {
      passwordField.type = 'password';
    }
  });
};

export default Apps;

import $ from 'jquery';
import { shortDate, shortDateWithYear } from 'date_utils';

// Much of the code here was taken from https://developers.google.com/drive/api/v3/quickstart/js, with thanks

const FileHelpers = () => {
  // Array of API discovery doc URLs for APIs
  var DISCOVERY_DOCS = [
    'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'
  ];

  var TODAY = new Date();

  // Authorization scopes required by the API; multiple scopes can be
  // included, separated by spaces.
  var SCOPES = 'https://www.googleapis.com/auth/drive.metadata.readonly';

  /**
   *  On page load, load the auth2 library and API client library.
   */
  $(document).on('turbolinks:load', event => {
    if ($('.g-files').length > 0) {
      try {
        gapi.load('client:auth2', initClient);
      } catch (err) {
        if (err instanceof ReferenceError) {
          console.log(
            `No GAPI, Google file integration will not work. We load the google API client with an async call that may
            take a while to initialise on your first visit to this page`
          );
        } else {
          console.error(err);
        }
      }
    }
  });

  /**
   *  Initializes the API client library and sets up sign-in state
   *  listeners.
   */
  const initClient = () => {
    var CLIENT_ID = $('.g-signin').data('client_id');
    var API_KEY = $('.g-signin').data('api_key');

    console.log(DISCOVERY_DOCS);

    gapi.client
      .init({
        apiKey: API_KEY,
        clientId: CLIENT_ID,
        discoveryDocs: DISCOVERY_DOCS,
        scope: SCOPES
      })
      .then(
        function() {
          // Listen for sign-in state changes.
          gapi.auth2.getAuthInstance().isSignedIn.listen(updateSigninStatus);
          // Handle the initial sign-in state.
          updateSigninStatus(gapi.auth2.getAuthInstance().isSignedIn.get());
        },
        function(error) {
          console.log(JSON.stringify(error, null, 2));
        }
      );
  };

  /**
   * Update the API appropriately for the latest sign in status
   */
  const updateSigninStatus = isSignedIn => {
    if (isSignedIn) {
      $('.g-signin').hide();
      $('.g-signout').show();
      $('.g-files').show();
      $('.list-buttons').show();
    } else {
      $('.g-signin').show();
      $('.g-signout').hide();
      $('.g-files').hide();
      $('.list-buttons').hide();
    }
  };

  $(document).on('click', '.g-signin', event => {
    console.log('Sign in event');
    gapi.auth2.getAuthInstance().signIn();
  });

  $(document).on('click', '.g-signout', event => {
    console.log('User signing out');

    if (gapi !== undefined) {
      var auth2 = gapi.auth2.getAuthInstance();
      auth2.signOut().then(function() {
        console.log('Finished sign out');
      });
    } else {
      console.log('No gapi');
    }
  });

  $(document).on('click', '.g-files', event => {
    event.preventDefault();
    $('.g-files').removeClass('selected');
    $(event.target).addClass('selected');
    $('.files').empty();
    var sortBy = $(event.target).data('sort');
    getFileList(sortBy);
  });

  const getFileList = orderBy => {
    var testFile = {
      name: 'Sample File (TEST ONLY)',
      id: '12345678',
      modifiedTime: '2019-11-08T04:57:26.615Z',
      viewedByMeTime: '2019-11-08T08:59:08.182Z',
      lastModifyingUser: {
        displayName: 'Sample Editor long name that should truncate',
        emailAddress: 'segglestn@gmail.com',
        kind: 'drive#user',
        me: true,
        permissionId: '02182928232908538395'
      }
    };
    // TEST ONLY
    //buildRow(testFile);

    if (gapi.client == undefined) {
      return;
    }

    gapi.client.drive.files
      .list({
        pageSize: 10,
        fields:
          'nextPageToken, files(id, name, modifiedTime, viewedByMeTime, lastModifyingUser)',
        orderBy: orderBy
      })
      .then(function(response) {
        var files = response.result.files;
        if (files && files.length > 0) {
          for (var i = 0; i < files.length; i++) {
            var file = files[i];
            buildRow(file);
          }
        } else {
          console.log('No files found.');
        }
      });
  };

  const buildRow = file => {
    var row = document.createElement('div');
    row.className = 'tr row';

    var name = document.createElement('div');
    name.className = 'cell col-3';
    var nameSpan = document.createElement('span');
    nameSpan.textContent = file.name;
    nameSpan.title = file.name;
    name.append(nameSpan);
    row.append(name);

    var edited = document.createElement('div');
    edited.className = 'cell col-2';
    edited.textContent = formatDate(file.modifiedTime);
    row.append(edited);

    var editedBy = document.createElement('div');
    editedBy.className = 'cell d-none d-md-block col-md-2';
    var editedBySpan = document.createElement('span');
    if (file.lastModifyingUser !== undefined) {
      editedBySpan.textContent = file.lastModifyingUser.displayName;
      editedBySpan.title = file.lastModifyingUser.displayName;
    }
    editedBy.append(editedBySpan);
    row.append(editedBy);

    var viewed = document.createElement('div');
    viewed.className = 'cell d-none d-sm-block col-sm-2';
    viewed.textContent = formatDate(file.viewedByMeTime);
    row.append(viewed);

    $('.files').append(row);
  };

  const formatDate = dateString => {
    if (dateString == undefined || dateString.length < 3) {
      return '';
    }
    var dateYear = parseInt(dateString.substr(0, 4));
    var date = new Date(dateString);
    var dateText = '';

    if (dateYear < TODAY.getYear()) {
      dateText = shortDateWithYear(date);
    } else {
      dateText = shortDate(date) + ' ' + formatTime(date);
    }

    return dateText;
  };

  const formatTime = date => {
    var options = {
      timeStyle: 'short'
    };

    var date = new Date(date);
    return date.toLocaleDateString('en-GB', options);
  };
};

export default FileHelpers;

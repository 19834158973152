import $ from 'jquery';
import { loadBookingHours } from './booking_tab_utils';
import { getCategory, updateForCategory } from './booking_category_utils';

const BookingFormTabs = () => {
  $(document).on('click', '.booking-form #not-working-tab', event => {
    showTimeOffTab();

    updateForCategory(getCategory());
  });

  $(document).on('click', '.booking-form #working-tab', event => {
    showWorkingTab();

    updateForCategory(getCategory());
  });

  $(document).on('click', '.booking-form #hours-tab', event => {
    showTimeTab();
  });
};

const showTimeTab = () => {
  //Show the booking hours and status
  $('.status-holder').show();
  $('.booked-times').show();
  //Hide the booking dates and details, both working and non-working
  $('.booking-details').hide();
  $('.working-details').hide();
  $('.not-working-categories').hide();
  $('.booking-dates').hide();

  $('#working-tab').removeClass('active');
  $('#hours-tab').addClass('active');

  $('.booking.submit-button').addClass('booking-time');
};

const showTimeOffTab = () => {
  // Show the booking details, dates and not working categories
  $('.booking-details').show();
  $('.booking-dates').show();
  $('.booking-done-day').hide();
  $('.not-working-categories').show();
  // Hide the project-related working details, hours, and status
  $('.working-details').hide();
  $('.booked-times').hide();
  $('.status-holder').hide();

  $('.booking.submit-button').removeClass('booking-time');
};

const showWorkingTab = () => {
  //Show the booking details, dates and project-related details
  $('.booking-details').show();
  $('.booking-dates').show();
  $('.booking-done-day').show();
  $('.working-details').show();
  // Hide the not working categories, hours and status (the latter two are on the hours tab)
  $('.not-working-categories').hide();
  $('.booked-times').hide();
  $('.status-holder').hide();

  $('.booking.submit-button').removeClass('booking-time');
};

export default BookingFormTabs;
export { showWorkingTab, showTimeOffTab, showTimeTab };

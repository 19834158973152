import $ from 'jquery';
import getBookingTasks from 'get_booking_tasks';
import getBookingTaskInfo from 'get_booking_task_info';
import invoiceProject from 'invoice_project';
import openDialog from 'open_dialog';
import { clearErrorAndWarn } from 'utils';

const BookingTasks = () => {
  $(document).on('change', '#booking_project_id', event => {
    event.preventDefault();

    getBookingTasks('project_id', event.target.value);
    invoiceProject(event.target.value, true);
  });

  $(document).on('change', '#booking_task_id', event => {
    event.preventDefault();

    if (event.target.value === '0') {
      openDialog('.dialog-create-task', 'dialog.titles.task');

      // Clear any errors that might have bled through from the booking form
      clearErrorAndWarn();
    } else {
      getBookingTaskInfo(event.target.value);
    }
  });

  $(document).on('click', '.dialog-create-task .hide-form', event => {
    $('.dialog-create-task').dialog('close');
    // If we leave the booking task id, it will show "[Create new task]", which is not right
    // The ideal would be to stash the old booking task id somewhere and restore it here
    // Until we get to that, just show a blank task
    // It's not great, but it's not as misleading as the "create new" option
    $('#booking_task_id').val('');
  });
};

export default BookingTasks;

import $ from 'jquery';

const RangeHelper = () => {
  $(document).on('keyup change', '.range input', event => {
    const $parent = $(event.target).closest('.range-holder');

    var level = levelForValue($(event.target)[0].value, $parent);
    changeText(level, $parent);
  });

  const changeText = (level, $parent) => {
    $parent.find('.eg').hide();
    $parent.find('.eg.' + level).show();
  };

  const levelForValue = (value, $parent) => {
    switch (value) {
      case '0':
        $parent.find('.example-holder').css('textAlign', 'right');
        return 'very-low';
      case '1':
        $parent.find('.example-holder').css('textAlign', 'right');
        return 'low';
      case '2':
        $parent.find('.example-holder').css('textAlign', 'left');
        return 'medium';
      case '3':
        $parent.find('.example-holder').css('textAlign', 'left');
        return 'high';
    }
  };
};

export default RangeHelper;

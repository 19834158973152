import $ from 'jquery';

const i18n = require('./i18n-en.113.json');

const translate = (key, fieldName) => {
  var keys = key.split('.');
  var translatedValue = i18n;

  keys.forEach(function(key) {
    translatedValue = translatedValue[key];
  });

  if (translatedValue === undefined) {
    translatedValue = key + ' WARNING: key not found';
  }

  if (fieldName) {
    translatedValue = fieldName + translatedValue;
  }

  return translatedValue;
};

export default translate;

import $ from 'jquery';
import { setError } from 'utils';

const ValidateItem = () => {
  $(document).on(
    'keyup change',
    '.item-form input, .item-form select',
    event => {
      validateNameLength();
      togglePrice(event.target);
    }
  );

  const validateNameLength = () => {
    var minLength = 3;
    var name = $('#item_name').val();
    if (name && name.length >= minLength) {
      $('.falert').hide();
      $('input.item').prop('disabled', false);
    } else {
      setError('errors.item.name_too_short');
    }
  };

  const togglePrice = target => {
    if (event.target.id == 'item_product') {
      $(event.target)
        .closest('.item')
        .find('.price')
        .toggleClass('hidden');
    }
  };
};

export default ValidateItem;

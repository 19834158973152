import $ from 'jquery';
import createBookingModal from 'booking_modal';

const viewConfigs = weekends => {
  const views = {
    resourceTimelineNinetyDay: {
      type: 'resourceTimeline',
      duration: { days: 93 },
      buttonText: 'Quarter',
      weekends: weekends,
      slotDuration: '24:00:00',
      slotLabelFormat: [{ month: 'long' }, { day: 'numeric' }]
    },

    resourceTimelineFourWeek: {
      type: 'resourceTimeline',
      duration: { days: 28 },
      buttonText: '4 weeks',
      weekends: weekends,
      slotDuration: '24:00:00',
      slotLabelFormat: [{ month: 'long' }, { day: 'numeric' }]
    },

    resourceTimelineThirtyDay: {
      type: 'resourceTimeline',
      duration: { days: 31 },
      buttonText: 'Month',
      weekends: weekends,
      slotDuration: '24:00:00',
      slotLabelFormat: [{ month: 'long' }, { day: 'numeric' }]
    },
    resourceTimelineTwentyDay: {
      type: 'resourceTimeline',
      duration: { days: 20 },
      buttonText: '20 days',
      weekends: weekends,
      slotDuration: '24:00:00',
      slotLabelFormat: [{ month: 'long' }, { day: 'numeric' }]
    },
    resourceTimelineTenDay: {
      type: 'resourceTimeline',
      duration: { days: 10 },
      buttonText: '10 days',
      weekends: weekends,
      slotDuration: '24:00:00',
      slotLabelFormat: [{ month: 'long' }, { day: 'numeric' }]
    },
    listWeek: {
      buttonText: 'List',
      weekends: weekends,
      duration: { days: 8 }
    }
  };

  return views;
};

const onDateClicked = info => {
  var resourceId = info.resource._resource.id;
  var $resource = $('td[data-resource-id=' + resourceId + ']').first();

  if ($resource.hasClass('feditable')) {
    var eventData = populateEventDefaults(info.date, info.date, resourceId);
    var bookingForSelf = resourceId == $('.current-user')[0].innerText;

    createBookingModal(eventData, bookingForSelf);
  }
};

const populateExistingEvent = info => {
  var resourceId = null;
  if (info.newResource) {
    resourceId = info.newResource._resource.id;
  } else {
    resourceId = info.oldEvent._def.resourceIds[0];
  }

  var finish = new Date(info.event.end);
  finish.setDate(finish.getDate() - 1);

  var eventData = populateEventDefaults(info.event.start, finish, resourceId);
  var category = info.event.extendedProps.category;

  eventData['extendedProps'] = info.event.extendedProps;
  const eventId = info.event.id.split('_')[1];
  eventData['id'] = eventId;

  return eventData;
};

const populateEventDefaults = (startDate, finishDate, bookingForId) => {
  var eventData = {};
  var extendedProps = {};

  var userId = $('.current-user')[0].innerText;

  extendedProps['userId'] = userId;
  extendedProps['feditable'] = true;
  extendedProps['booked_by'] = $('.current-user-name')[0].innerText;

  eventData['start'] = startDate;
  eventData['finish'] = finishDate;
  eventData['extendedProps'] = extendedProps;
  eventData['resourceIds'] = [bookingForId];

  return eventData;
};

export {
  viewConfigs,
  onDateClicked,
  populateEventDefaults,
  populateExistingEvent
};

import $ from 'jquery';

const Carousel = () => {
  var slideIndex = 0;
  // Only use carousel in mobile and tablet portrait
  const mobile = window.matchMedia('(max-width: 992px)');
  if (mobile.matches) {
    carousel();
  }

  function carousel() {
    var $slides = $(document).find('.carousel');

    for (var i = 0; i < $slides.length; i++) {
      $slides[i].style.display = 'none';
    }

    $('.solid').removeClass('solid');

    $('.btn' + slideIndex).addClass('solid');
    var $slide = $('.img' + slideIndex);
    if ($slide) {
      $slide.fadeIn(2000);
    }
    slideIndex++;
    if (slideIndex >= $slides.length) {
      slideIndex = 0;
    }
    setTimeout(carousel, 15000); // Wait time in milliseconds
  }
};

export default Carousel;

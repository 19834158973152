import $ from 'jquery';
import getBookingTasks from 'get_booking_tasks';
import toggleCheckbox from 'toggle_checkbox';

const ProjectSelects = () => {
  $(document).on('click', '.project-form .project_customer_id', event => {
    event.preventDefault();
    setProjectName($(event.target));
  });

  $(document).on('change', '.project-service select', event => {
    event.preventDefault();
    setTasksForService($(event.target).val());
  });

  $(document).on('click', '.project-form .select-all', event => {
    event.preventDefault();
    allTaskSelection();
  });

  $(document).on('click', '.project-form .select-none', event => {
    event.preventDefault();
    clearTaskSelection();
  });

  const setProjectName = $target => {
    // TODO: SET PROJECT NAME
  };

  const setTasksForService = serviceId => {
    clearTaskSelection();
    if (parseInt(serviceId) > 0) {
      getBookingTasks('service_id', serviceId);
    }
  };

  const clearTaskSelection = () => {
    $('.project-form .checkbox-holder').each(function(index, element) {
      toggleCheckbox(element, false);
    });
  };

  const allTaskSelection = () => {
    $('.project-form .checkbox-holder').each(function(index, element) {
      toggleCheckbox(element, true);
    });
  };
};

export default ProjectSelects;

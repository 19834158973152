import $ from 'jquery';
import { setError, clearErrorAndWarn } from 'utils';

const ValidateSyntax = () => {
  var minLength = 3;
  $(document).on('mouseenter', '.customer-form input[type="submit"]', event => {
    event.preventDefault();
    validateFields(event.target);
  });

  // Phone is outside required-fields, so it misses out on that reset
  $(document).on('mouseenter', '.customer-form .customer_phone', event => {
    clearErrorAndWarn();
  });

  const validateFields = target => {
    $('input.customer').prop('disabled', true);

    var $form = $(target.closest('.customer-form'));

    $('input.customer').prop('disabled', false);

    validateExpression($form);
  };

  const validateExpression = $form => {
    var emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    var phonePattern = /^[\d ()+-]+$/;
    var emailValid = true;
    var phoneValid = true;

    var $email = $form.find('.customer_email');
    if ($email.length > 0) {
      var emailAddr = $email.find('input')[0].value;
      // Email is required, but presence is validated elsewhere. Skip this test if there are no contents
      if (emailAddr.length > 0) {
        emailValid = emailPattern.test(emailAddr);
      }
    }

    var $phone = $form.find('.customer_phone');
    if ($phone.length > 0) {
      var phoneNum = $phone.find('input')[0].value;
      // Phone number is optional, so 0 length is fine
      if (phoneNum.length > 0) {
        phoneValid = phoneNum.length > 5 && phonePattern.test(phoneNum);
      }
    }

    if (emailValid && phoneValid) {
      return true;
    }

    if (!phoneValid) {
      setError('errors.user.phone_not_valid', $form.find('.falert'));
    }

    // Make email last, this is the one that will display if both phone and email are invalid
    if (!emailValid) {
      setError('errors.user.email_not_valid', $form.find('.falert'));
    }

    return false;
  };
};

export default ValidateSyntax;

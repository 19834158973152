import $ from 'jquery';
import { getWorkingHours } from './working_hour_utils';
import { validateDate } from 'date_utils';

const configureForUser = () => {
  // Figure out who the user is booking for
  const currentUser = $('.current-user')[0].innerText;
  const bookingFor = $('select#booking_user_id').val();
  const category = 'project';

  let bookingForSelf = false;
  if (bookingFor && currentUser === bookingFor) {
    bookingForSelf = true;
  }

  // Update the working hours for the user
  // If the start or finish dates are invalid, set the booking hours to blank
  var startDate = validateDate('booking_start');

  var finishDate = validateDate('booking_finish');

  let hourNum;
  if (!startDate || !finishDate) {
    hourNum = '';
  } else {
    hourNum = getWorkingHours(startDate, finishDate, category);
  }

  $('#booking_hours').val(hourNum);
};

export default configureForUser;

import $ from 'jquery';

const FilterTasks = () => {
  $(document).on('change', '.task-service', event => {
    var url = '/tasks?service_id=' + $(event.target).val();
    Turbolinks.visit(url);
  });

  $(document).on('change', '.task-project', event => {
    var url = '/tasks?project_id=' + $(event.target).val();
    Turbolinks.visit(url);
  });
};

export default FilterTasks;

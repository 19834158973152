import $ from 'jquery';

import translate from 'i18n';

const InvoiceEntryModal = () => {
  $(document).on('click', '.entry-form .close-form', event => {
    $('.entry-form').dialog('close');
  });

  $(document).on('click', '.add-entry', event => {
    event.preventDefault();

    createEntryModal();
  });

  const createEntryModal = () => {
    var dialogWidth = 335;
    var title = translate('invoice.entry');

    $('.entry-form')
      .dialog({
        modal: true,
        show: 'show',
        width: dialogWidth,
        title: title,
        dialogClass: 'entry-dialog'
      })
      .prev()
      .find('.ui-dialog-titlebar-close')
      .hide();
  };
};

export default InvoiceEntryModal;

import $ from 'jquery';
import { ajaxCall } from 'ajax_call';

const CallServer = () => {
  $(document).on('click', '.call-server', event => {
    event.preventDefault();
    event.stopPropagation();

    const data = $(event.target).data();

    var dayOfMonth = $('.payment-day input').val();
    if (dayOfMonth) {
      data.day = dayOfMonth;
    }

    var name = $('.task_name input').val();
    if (name) {
      var desc = $('.task-description textarea').val();
      var hours = $('.task_hours input').val();
      data.task = {
        name: name,
        int_description: desc,
        hours: hours
      };
      data.method = 'post';
    }

    if (data.score) {
      data.skill_id = $(event.target)
        .closest('.user_skill')
        .data().id;
    }

    var url = data.url;

    var method = 'get';
    if (data.method) {
      method = data.method;
      delete data.method;
    }

    ajaxCall(method, url, data);

    return false;
  });
};

export default CallServer;

import $ from 'jquery';

const Privilege = () => {
  $(document).on('click', '.next-privilege', event => {
    clear();
    const $button = $(event.target);

    if ($button.hasClass('you')) {
      showAppearance();
    } else if ($button.hasClass('your_appearance')) {
      showFamily();
    }
  });

  $(document).on('click', '.prev-privilege', event => {
    clear();
    $('.column').hide();
    window.scrollTo(0, 0);

    const $button = $(event.target);

    if ($button.hasClass('your_appearance')) {
      showYou();
    } else if ($button.hasClass('your_family')) {
      showAppearance();
    }
  });

  $(document).on('click', '.p-step', event => {
    clear();
    const $button = $(event.target);
    if ($button.hasClass('one')) {
      showYou();
    } else if ($button.hasClass('two')) {
      showAppearance();
    } else if ($button.hasClass('three')) {
      showFamily();
    }
  });

  const clear = () => {
    $('.column').hide();
    $('.p-step').removeClass('highlight');
    window.scrollTo(0, 0);
  };

  const showYou = () => {
    $('.column.you').show();
    $('.column.you')
      .find('.one')
      .addClass('highlight');
    $('.save').hide();
  };

  const showAppearance = () => {
    $('.column.appearance').show();
    $('.column.appearance')
      .find('.two')
      .addClass('highlight');
    $('.save').hide();
  };

  const showFamily = () => {
    $('.column.family').show();
    $('.column.family')
      .find('.three')
      .addClass('highlight');
    $('.save').show();
  };
};

export default Privilege;

import $ from 'jquery';

const getProjectResources = (fetchInfo, successCallback) => {
  $.ajax({
    url: '/project_resource_data',
    data: { id: $('.project-calendar')[0].id }
  }).done(function(response) {
    successCallback(response); //return resource data to the calendar via the provided callback function
  });
};

const getResources = (fetchInfo, successCallback, onlySelf) => {
  $.ajax({
    url: '/resource_data',
    data: {
      onlyself: onlySelf,
      team_id: $('.calendar-team select').val(),
      skill_id: $('.calendar-skill select').val(),
      score: $('.calendar-score select').val()
    }
  }).done(function(response) {
    successCallback(response); //return resource data to the calendar via the provided callback function
  });
};

const workingAndVacation = (element, resourceProps) => {
  $(element).attr('data-hours', resourceProps.working_hours);
  $(element).attr(
    'data-available-this-year',
    resourceProps.available_this_year
  );
  $(element).attr(
    'data-available-next-year',
    resourceProps.available_next_year
  );
};

const laneClass = (resource, inheritEditable) => {
  var classes = ['resource'];

  if (resource.id < 0) {
    classes.push('fade-resource');
  }

  if (resource.extendedProps.working_in_days) {
    classes.push('days');
  } else {
    classes.push('hours');
  }

  if (inheritEditable && resource.extendedProps.feditable) {
    classes.push('feditable');
  }

  if (resource.extendedProps.working_in_days) {
    classes.push('days');
  } else {
    classes.push('hours');
  }

  return classes;
};

const labelContent = (resource, createElement) => {
  var title = resource._resource.title;

  if (resource.extendedProps.skill) {
    title +=
      "<br/><span class='skill fc-cell-text'>" +
      resource.extendedProps.skill +
      '</span>';
  }

  return { html: title };
};

export {
  getResources,
  getProjectResources,
  laneClass,
  labelContent,
  workingAndVacation
};

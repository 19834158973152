import $ from 'jquery';
import translate from 'i18n';

import { setError } from 'utils';

const SUNDAY = 0;
const SATURDAY = 6;
const DAY_CLASSES = ['.sun', '.mon', '.tue', '.wed', '.thu', '.fri', '.sat'];

const month = [
  translate('date.month.january'),
  translate('date.month.february'),
  translate('date.month.march'),
  translate('date.month.april'),
  translate('date.month.may'),
  translate('date.month.june'),
  translate('date.month.july'),
  translate('date.month.august'),
  translate('date.month.september'),
  translate('date.month.october'),
  translate('date.month.november'),
  translate('date.month.december')
];

const validateDate = (fieldsPrefix, allowLeapDay = true, parent) => {
  $('.falert').hide();

  const dateArray = dateForField(fieldsPrefix, parent);

  // When it's towards the end of the month, the way the date is built can go astray
  // Start from 1 Jan for safety
  // Also, use midday (12), not midnight (0), because otherwise timezone issues means the iso formatted day can be wrong
  let date = new Date(new Date().getFullYear(), 0, 1, 12);
  if (dateArray[2]) date.setYear(dateArray[2]);
  if (dateArray[1]) date.setMonth(dateArray[1] - 1);
  if (dateArray[0]) date.setDate(dateArray[0]);

  if (
    date.getDate() != dateArray[0] ||
    (!allowLeapDay && dateArray[1] == 2 && dateArray[0] == 29)
  ) {
    $('input[type="submit"]').prop('disabled', false);

    const dateText = dateArray[0] + ' ' + month[dateArray[1] - 1];
    setError(
      'errors.date.not_valid',
      getAlertField(fieldsPrefix, parent),
      dateText
    );
    return false;
  }

  return date;
};

const dateExcludingWeekends = date => {
  if (date.getDay() == SATURDAY) {
    return new Date(date.setDate(date.getDate() + 2));
  }

  if (date.getDay() == SUNDAY) {
    return new Date(date.setDate(date.getDate() + 1));
  }

  return date;
};

const dateSmartWeekends = date => {
  var weekends = $('#weekends').val() === 'true';

  if (weekends) {
    return date;
  }

  return dateExcludingWeekends(date);
};

const yesterdayExcludingWeekends = () => {
  const date = new Date();
  date.setDate(date.getDate() - 1);

  if (date.getDay() == SATURDAY) {
    return new Date(date.setDate(date.getDate() - 1));
  }

  if (date.getDay() == SUNDAY) {
    return new Date(date.setDate(date.getDate() - 2));
  }

  return new Date(date);
};

const dateForField = (fieldsPrefix, parent) => {
  const dateField = getField('#' + fieldsPrefix + '_3i', parent);
  const monthField = getField('#' + fieldsPrefix + '_2i', parent);
  const yearField = getField('#' + fieldsPrefix + '_1i', parent);

  const date = dateField ? dateField.value : false;
  const month = monthField ? monthField.value : false;
  const year = yearField ? yearField.value : false;

  return [parseInt(date), parseInt(month), parseInt(year)];
};

const getAlertField = (fieldsPrefix, parent) => {
  let field = getField('#' + fieldsPrefix + '_3i', parent);
  if (!field) {
    field = getField('#' + fieldsPrefix + '_2i', parent);
  }
  if (!field) {
    field = getField('#' + fieldsPrefix + '_1i', parent);
  }

  return $(field.closest('.form')).find('.falert');
};

const getField = (fieldId, parent) => {
  if (parent) return parent.find(fieldId)[0];

  return $(fieldId)[0];
};

const shortDateWithYearAndDay = date => {
  var options = {
    weekday: 'short',
    year: '2-digit',
    month: 'short',
    day: 'numeric'
  };

  return (
    date
      .toLocaleDateString('en-GB', options)
      .replace(',', '')
      // Only needed with en-GB, for some reason this is different from the US version
      // But we want to keep the GB options for date order
      .replace('Sept', 'Sep')
  );
};

const getWeekday = date => {
  var options = {
    weekday: 'long'
  };

  return date.toLocaleDateString('en-GB', options);
};

const shortDateWithYear = date => {
  var options = {
    year: '2-digit',
    month: 'short',
    day: 'numeric'
  };

  return (
    date
      .toLocaleDateString('en-GB', options)
      .replace(',', '')
      // Only needed with en-GB, for some reason this is different from the US version
      // But we want to keep the GB options for date order
      .replace('Sept', 'Sep')
  );
};

const shortDate = date => {
  var options = {
    month: 'short',
    day: 'numeric'
  };

  return (
    date
      .toLocaleDateString('en-GB', options)
      .replace(',', '')
      // Only needed with en-GB, for some reason this is different from the US version
      // But we want to keep the GB options for date order
      .replace('Sept', 'Sep')
  );
};

const calendarDate = pageURL => {
  var startDate = new Date().setHours(1, 0, 0, 0);

  var dateIndex = pageURL.indexOf('start=');
  if (dateIndex > 0) {
    startDate = pageURL.substring(dateIndex + 6, dateIndex + 16);
  }

  return startDate;
};

const dateIsBeforeToday = date => {
  const today = new Date();
  if (date.getTime() < today.getTime()) {
    return true;
  }

  return false;
};

export {
  dateIsBeforeToday,
  validateDate,
  dateExcludingWeekends,
  dateSmartWeekends,
  yesterdayExcludingWeekends,
  shortDateWithYearAndDay,
  shortDateWithYear,
  shortDate,
  getWeekday,
  calendarDate,
  SATURDAY,
  SUNDAY,
  DAY_CLASSES
};

import $ from 'jquery';

const currencies = require('./currency.json');

const PricingLocale = () => {
  $(document).on('change', '.input.currency select', event => {
    var country = $(event.target).val();

    $('.price-card.startup:visible h3')[0].innerText =
      currencies[country]['startup'];
    $('.price-card.scale:visible h3')[0].innerText =
      currencies[country]['scale'];
    $('.price-card.free:visible h3')[0].innerText = currencies[country]['free'];
  });
};

export default PricingLocale;

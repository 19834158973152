import $ from 'jquery';

const FilterResults = () => {
  $(document).on('click', '.check-icon', event => {
    $(event.target)
      .closest('.check-icon')
      .toggleClass('checked');

    var $icons = $('.table-header .checked');
    var flags = '';
    $icons.each(function(index, icon) {
      if (flags.length === 0) {
        flags += '?' + $(icon).data().action + '=true';
      } else {
        flags += '&' + $(icon).data().action + '=true';
      }
    });

    var type = $('.table-header').data().type;
    var url = '/' + type + flags;

    $icons.attr('href', url);

    return true;
  });
};

export default FilterResults;

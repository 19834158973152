import $ from 'jquery';
import openDialog from './open_dialog';

const BookedTimeHelper = () => {
  $(document).on('click', '.delete.btn.destroy', event => {
    event.preventDefault();
    openDialog('.dialog-confirm-delete', 'dialog.titles.confirm_delete');
  });

  $(document).on('click', '.dialog-confirm-delete .hide-form', event => {
    $('.dialog-confirm-delete').dialog('close');
  });
};

export default BookedTimeHelper;

import $ from 'jquery';
import translate from 'i18n';

const DialogHelper = () => {
  $(document).ready(function() {
    $('.dialog-info-hours').dialog({
      autoOpen: false,
      modal: true,
      width: 390,
      dialogClass: 'info-dialog',
      title: translate('dialog.titles.hours')
    });

    $('.dialog-info-price').dialog({
      autoOpen: false,
      modal: true,
      width: 390,
      dialogClass: 'info-dialog',
      title: translate('dialog.titles.price')
    });

    $('.dialog-info-quantity').dialog({
      autoOpen: false,
      modal: true,
      width: 390,
      dialogClass: 'info-dialog',
      title: translate('dialog.titles.quantity')
    });

    $('.dialog-info-features').dialog({
      autoOpen: false,
      modal: true,
      width: 585,
      dialogClass: 'info-dialog',
      title: translate('dialog.titles.features')
    });

    $(document).on('click', '.fa-info-circle', event => {
      var modalName = '.dialog-' + $(event.target)[0].classList[2];
      $(modalName).dialog('open');
    });
  });
};

export default DialogHelper;

import $ from 'jquery';
import translate from 'i18n';

const ValidateOrder = () => {
  $(document).on(
    'keyup change',
    '.order-form input, .order-form select',
    event => {
      var $row = $(event.target).closest('.row-parent');
      validateCodeLength($row);
    }
  );

  const validateCodeLength = $row => {
    var minLength = 5;
    var code = $row.find('#order_code').val();
    if (code && code.length >= minLength) {
      $('.order-form')
        .find('.falert')
        .hide();
      $('input.order').prop('disabled', false);
    } else {
      var translatedText = translate('errors.order.too_short');
      $('input.order').prop('disabled', true);
      $('.order-form').find('.falert')[0].textContent = translatedText;

      $('.order-form')
        .find('.falert')
        .show();
    }
  };
};

export default ValidateOrder;

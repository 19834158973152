import $ from 'jquery';

import { ajaxCall } from 'ajax_call';
import { validateDate } from 'date_utils';

const UpdateGenerate = () => {
  $(document).on(
    'change',
    '#project_start_date_3i, #project_start_date_2i, #project_start_date_1i',
    event => {
      event.stopImmediatePropagation();

      const date = validateDate('project_start_date', false);

      // No need to save date changes immediately, instead use them to update the date variable
      // on the reschedule button, which is used for the ajax call
      if (date) {
        const dateString =
          date.getDate() +
          '-' +
          (date.getMonth() + 1) +
          '-' +
          date.getFullYear();

        const scheduleButton = $('.call-server.generate');
        if (scheduleButton.length > 0) {
          var url = scheduleButton.data('url').split('date=')[0];
          url = url + 'date=' + dateString;
          scheduleButton.data('url', url);
        }

        const rescheduleButton = $('.call-server.secondary');
        if (rescheduleButton.length > 0) {
          url = rescheduleButton.data('url').split('date=')[0];
          url = url + 'date=' + dateString;
          rescheduleButton.data('url', url);
        }
      }
    }
  );
};

export default UpdateGenerate;

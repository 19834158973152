import $ from 'jquery';
import translate from 'i18n';
import { validateDate, shortDateWithYearAndDay, getWeekday } from 'date_utils';
import { getWorkingHourData } from 'working_hour_utils';
import workingDays from 'working_days';

const updateDaysAndReturnDay = (
  startDate,
  finishDate,
  completedDate,
  target
) => {
  dayOfWeek(startDate, finishDate, target);
  workingDays(startDate, finishDate, true);
  completionDate(finishDate, completedDate);
};

const completionDate = (finishDate, completedDate) => {
  if ($('.return').length === 0) {
    return null;
  }

  const status = $('#booking_status')[0].value;

  var endDate = new Date(finishDate);
  var title = translate('booking.plan');

  if (status === 'complete') {
    // We only have a completed date when we first load the modal,
    // on button clicks it might be null.
    // If we have the completed date, set it now
    if (completedDate) {
      endDate = new Date(completedDate);
      title = translate('booking.completed');
    } else {
      return null;
    }
  }

  $('.booking-done-day').find('label')[0].textContent = title;
  updateReturnDay(endDate);
};

const updateReturnDay = endDate => {
  var endDateText = shortDateWithYearAndDay(endDate);
  $('.return')[0].innerText = endDateText;
};

const updateFinishForHours = hours => {
  const category = 'project';
  var startDate = validateDate('booking_start');
  var finishDate = startDate;
  var hourData = getWorkingHourData(category);
  var hourNum = hoursForDate(hourData, finishDate);
  var i = 0;

  while (hours > hourNum && i < 39) {
    // Temporary loop catcher, better fix coming in Trello 1153
    i++;
    // Add a full day to the finish date
    finishDate = new Date(finishDate.getTime() + 86400000);
    hourNum += hoursForDate(hourData, finishDate);
  }

  $('#booking_finish_3i').val(finishDate.getDate());
  $('#booking_finish_2i').val(finishDate.getMonth() + 1);
  $('#booking_finish_1i').val(finishDate.getFullYear());

  updateReturnDay(finishDate);

  $('.booking-date.finish .cwday')[0].innerText = getWeekday(finishDate);
};

const hoursForDate = (hourData, date) => {
  var day = date.getDay() - 1;
  if (day < 0) {
    day = 6;
  }

  return parseFloat(hourData[day]);
};

const dayOfWeekFor = (date, field) => {
  var dayText = getWeekday(date);
  var $wDay = $(field)
    .closest('.booking-date')
    .find('.cwday');

  $wDay[0].innerText = dayText;
};

const dayOfWeek = (startDate, finishDate, target) => {
  dayOfWeekFor(startDate, '.booking-dates .start');
  dayOfWeekFor(finishDate, '.booking-dates .finish');
};

export { updateDaysAndReturnDay, updateFinishForHours, updateReturnDay };

import $ from 'jquery';
import { shortDateWithYearAndDay } from 'date_utils';

const Payments = () => {
  $(document).on('change', '.payment-day input', event => {
    var newDay = $(event.target).val();

    var date = new Date();
    var dayOfMonth = date.getDate();
    var month = date.getMonth();

    if (newDay <= dayOfMonth) {
      month += 1;
    }
    date.setMonth(month);
    date.setDate(newDay);
    var dateText = shortDateWithYearAndDay(date);

    $('.pay-date').text(dateText);
  });
};

export default Payments;

import $ from 'jquery';
import translate from 'i18n';
import { ajaxCall } from 'ajax_call';

const Configurator = () => {
  $(document).on('click', '.configurator', event => {
    event.preventDefault();

    var dialogWidth = 480;
    var maxHeight = $(window).height();

    $('.configurator-form')
      .dialog({
        modal: true,
        show: 'show',
        width: dialogWidth,
        maxHeight: maxHeight,
        position: { my: 'middle', at: 'top', of: $('.explainer') },
        title: translate('dialog.titles.configurator'),
        dialogClass: 'configurator-dialog'
      })
      .prev()
      .find('.ui-dialog-titlebar-close')
      .hide(); // Hide the standard close button;

    // If a user relaunches without refreshing the page, old selections might still be visible
    // Hide everything before we start
    $('.feature-summary').hide();
    $('.configurator-row').hide();

    $('.configurator-row.time').show();
  });

  $(document).on('click', '.configurator-form .close-form', event => {
    $('.configurator-form').dialog('close');

    if ($(event.target).hasClass('finish')) {
      const url = $(event.target).data().url;
      ajaxCall('put', url, {});
    }
  });

  $(document).on('click', '.enable', event => {
    event.preventDefault();
    // Rely on the first class to have the name of the field that was updated
    const feature = event.target.classList[0];

    const $parentRow = $(event.target).closest('.configurator-row');

    $parentRow.find('.on').toggleClass('hidden');
    $parentRow.find('.off').toggleClass('hidden');
    $parentRow.find('.on-desc').toggleClass('strong');
    $parentRow.find('.off-desc').toggleClass('strong');
    const oldVal = $parentRow.find('.feature.hidden').val();
    // Update with the opposite value. It's stored as a string, so we can't do a boolean test
    $parentRow.find('.feature.hidden').val(oldVal === 'false');

    return false;
  });

  $(document).on('click', '.next', event => {
    // Relying on the first class to have the name of the field that was updated
    const feature = event.target.classList[0];

    sendFeatureUpdate(feature, false);
  });

  $(document).on('click', '.back', event => {
    const $summary = $(event.target).closest('.feature-summary');
    const toFeature = $(event.target).data('back');

    if ($summary.length > 0) {
      $('.feature-summary').hide();
      $('div.' + toFeature).show();
    } else {
      // Hide the current contents
      $(event.target)
        .closest('.configurator-row')
        .hide();

      const fromFeature = $(event.target).closest('.configurator-row')[0]
        .classList[2];

      // List should match features_with_dependencies in Setting model
      if (
        $.inArray(toFeature, ['time', 'skills', 'templates', 'make_public']) >=
        0
      ) {
        const $fromFeatureRow = $('div.' + fromFeature);
        const $toFeatureRow = $('div.' + toFeature);

        if ($fromFeatureRow.find('.off').hasClass('hidden')) {
          $toFeatureRow.find('.switch-toggle').attr('disabled', true);
          $toFeatureRow.find('.on-required').show();
          $toFeatureRow.find('.switch-toggle').addClass('disabled');
        } else {
          $toFeatureRow.find('.switch-toggle').attr('disabled', false);
          $toFeatureRow.find('.switch-toggle').removeClass('disabled');
          $toFeatureRow.find('.on-required').hide();
        }
      }

      sendFeatureUpdate(fromFeature, true);
    }
  });

  const sendFeatureUpdate = (feature, back) => {
    const data = $(event.target).data();
    const $parentRow = $(event.target).closest('.configurator-row');
    const checkbox = $parentRow.find('.feature.hidden')[0];

    const textField = $parentRow.find('.feature-text.' + feature);
    var text = '';

    if (textField.length > 0) {
      text = '&' + feature + '_tool=' + textField[0].value;
    }

    const url = data.url + checkbox.value + text + '&back=' + back;

    // Hide the previous feature contents
    // The new one will be shown after we get the new key back from the ajax call
    $(event.target)
      .closest('.configurator-row')
      .hide();

    // Don't pass the data through, it would only send the url to the controller, replace with empty hash
    ajaxCall('put', url, {});
  };
};

export default Configurator;

import $ from 'jquery';
import { validateDate } from 'date_utils';

const ValidatePublicHoliday = () => {
  $(document).on('change', '.public_holiday_holiday_date select', event => {
    $('.falert').hide();
    $('input.public_holiday').prop('disabled', false);

    const parent = $(event.target).closest('.foldout-form');
    validateDate('public_holiday_holiday_date', true, parent);
  });
};

export default ValidatePublicHoliday;

import $ from 'jquery';

const toggleCheckbox = (checkboxHolder, checked) => {
  const input = $(checkboxHolder).find('input.boolean')[0];
  const hiddenInput = $(checkboxHolder).find("input[type='hidden']")[0];
  const id = input.id;

  if (checked) {
    $(checkboxHolder).addClass('checked');
    $(input).prop('checked', true);
    hiddenInput.value = 1;
    // Although it is a dummy input, the value is used to evaluate required fields,
    // because the actual input is hidden
    if ($(checkboxHolder).hasClass('ts-and-cs')) {
      $(input).val('1');
    } else if ($(checkboxHolder).hasClass('church-roll')) {
      $(input).val('1');
    }
  } else {
    $(checkboxHolder).removeClass('checked');
    $(input).prop('checked', false);
    hiddenInput.value = 0;
    // Although it is a dummy input, the value is used to evaluate required fields,
    // because the actual input is hidden
    if ($(checkboxHolder).hasClass('ts-and-cs')) {
      $(input).val('');
    } else if ($(checkboxHolder).hasClass('church-roll')) {
      $(input).val('');
    }
  }

  if ($(checkboxHolder).hasClass('task')) {
    hiddenInput.name = 'project[task_ids][' + id + ']';
    hiddenInput.id = 'project_task_ids_' + id;
  }
};

export default toggleCheckbox;

import $ from 'jquery';

const updateEvent = eventId => {
  if ($('.calendar-form').length === 0) {
    return;
  }

  var action = $('.new_booking')[0].action;
  if (action.endsWith('bookings')) {
    $('.new_booking')[0].action = action + '/' + eventId;
  }

  if ($('meta[name="csrf-token"]').length > 0) {
    $('input[name="authenticity_token"]').val(
      $('meta[name="csrf-token"]')[0].content
    );
  }

  var $update = document.createElement('input');
  $update.type = 'hidden';
  $update.name = '_method';
  $update.value = 'patch';

  $('.new_booking').append($update);
};

export default updateEvent;

import $ from 'jquery';
import { setError } from 'utils';

const ValidateAccount = () => {
  $(document).on('keyup change', '.account_name input', event => {
    initSafeName(event.target.value);
  });

  // When a user tries to submit, validate everything if there is a required-fields section
  $(document).on('mouseover focus', '.account', event => {
    var accountName = $('#account_name');
    if (accountName.length > 0) {
      initSafeName(accountName.val());
    }
  });

  const initSafeName = accountName => {
    $('.account').prop('disabled', true);

    checkLength(accountName);
    checkValid(accountName);

    accountName = accountName.split(' ').join('');
    accountName = accountName.toLowerCase();

    checkExists(accountName);
    updateExample(accountName);
  };

  const checkValid = accountName => {
    if (accountName.length < 3) {
      return;
    }

    var regex = new RegExp('^[-a-zA-Z0-9 ]+$');
    if (!regex.test(accountName)) {
      setError('errors.account.not_valid');
    }
  };

  const checkLength = accountName => {
    if (accountName.length < 3) {
      setError('errors.account.too_short');
    } else if (accountName.length > 14) {
      setError('errors.account.too_long');
    } else if (accountName == 'www') {
      setError('errors.account.reserved');
    } else {
      $('.card-body .falert').hide();
      $('.account').prop('disabled', false);
    }
  };

  const checkExists = accountName => {
    $.ajax({
      url: '/account_name_already_exists',
      dataType: 'json',
      data: { account_name: accountName },
      success: function(response) {
        if (response.matched) {
          setError('errors.account.duplicate');
        }
      }
    });
  };

  const updateExample = accountName => {
    $('.corp-name')[0].textContent = accountName + '.';
  };
};

export default ValidateAccount;

import $ from 'jquery';
import { setError } from 'utils';
import fieldLength from 'field_length';

const ValidateUser = () => {
  $(document).on('click', '.reset', event => {
    resetVacation(event.target);
  });

  $(document).on('change', '.new_user input', event => {
    $('.falert').hide();
    $('input[type="submit"]').prop('disabled', false);

    if (event.target.classList.contains('email')) {
      validateEmail();
    }
  });

  $(document).on('change', '.edit_user select', event => {
    if (event.target.classList.contains('role')) {
      var $teamDiv = $(event.target)
        .closest('.tr')
        .find('.user-team');
      if (event.target.value === 'unapproved') {
        $teamDiv.addClass('disabled');
        $teamDiv.find('select').prop('disabled', true);
      } else {
        $teamDiv.removeClass('disabled');
        $teamDiv.find('select').prop('disabled', false);
      }
    }
  });

  // When a user tries to submit, validate the email
  $(document).on('mouseover focus', '.new-user input[type="submit"]', event => {
    validateEmail();
  });

  const resetVacation = target => {
    var $form = $(target).closest('.foldout-holder');

    var number = $form.find('.number')[0].innerText;
    var $vacationNumberField = $form.find('#user_vacation_number');
    $vacationNumberField.val(number);

    var type = $form.find('.type')[0].innerText;
    var $vacationTypeField = $form.find('#user_vacation_time_scale');
    $vacationTypeField.val(type);
  };

  const validateEmail = () => {
    var pattern = /\S+@\S+\.\S+/;
    var emailText = $('input[type="email"]').val();

    if (emailText.length === 0) {
      return true;
    }

    if (!pattern.test(emailText)) {
      setError('errors.user.email_not_valid');
      return false;
    }

    return true;
  };
};

export default ValidateUser;

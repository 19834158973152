import $ from 'jquery';

const Hamburger = () => {
  const toggle = () => $('.site-nav').toggleClass('open');

  $(document).on('click', '.hamburger', toggle);

  $(document).on('click', '.close-menu', event => {
    $('.site-nav').removeClass('open');
  });
};

export default Hamburger;

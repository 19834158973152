import $ from 'jquery';
import { setError } from 'utils';
import setWarn from 'setWarn';

const anyEventsForDay = (
  start,
  finish,
  userId,
  existingEventId,
  isVacation
) => {
  var events = $(".fc-timeline-body [data-resource-id='" + userId + "']").find(
    '.fc-event'
  );
  var eventDates = [];

  for (var i = 0; i < events.length; i++) {
    var contents = {
      id: events[i].id,
      start: $(events[i])[0]['fcSeg'].start,
      finish: $(events[i])[0]['fcSeg'].end,
      classList: events[i].classList
    };
    eventDates.push(contents);
  }

  var date = new Date(start);
  var endDate = new Date(finish);

  while (date <= endDate) {
    if (dateClashes(date, eventDates, existingEventId, isVacation)) {
      // Overlap of vacation bookings, break out of the test and return
      return true;
    }
    date.setDate(date.getDate() + 1);
  }

  // If we get here, there's either no error, or the overlap is not for a vacation booking and is only a warning
  return false;
};

const dateClashes = (date, events, existingEventId, isVacation) => {
  var BreakException = {};

  try {
    events.forEach(function(calEvent) {
      // Skip public holidays
      if (calEvent.classList.contains('public-holiday')) {
        return;
      }
      // Skip non-working days
      if (calEvent.classList.contains('non-working-day')) {
        return;
      }
      // Skip if we're updating the same event
      if (calEvent.id == existingEventId) {
        return;
      }

      if (calEvent.start <= date.setHours(23, 59, 59, 0)) {
        if (calEvent.finish > date.setHours(23, 59, 59, 0)) {
          if (isVacation) {
            throw BreakException;
          } else if (calEvent.classList.contains('vacation')) {
            throw BreakException;
          } else {
            setWarn('warnings.booking.overlap');
          }
        }
      }
    });
  } catch (be) {
    // If it's not a break exception, re-throw it
    if (be !== BreakException) {
      throw be;
    }

    // If we got a break exception, set a warning, break out of the test and return
    setWarn('warnings.booking.overlap');
    return true;
  }

  return false;
};

export default anyEventsForDay;

import $ from 'jquery';
import { setInvoiceInfo } from './booking_category_utils';

const invoiceProject = (projectId, useProjectDefault) => {
  if (projectId) {
    if (useProjectDefault) {
      const ids = $('.invoiceable-projects').data().ids;
      if (ids.includes(parseInt(projectId))) {
        setInvoiceInfo(true);
      } else {
        setInvoiceInfo(false);
      }
    }

    $('.booking-invoice .checkbox input').attr('disabled', false);
    $('.booking-invoice').removeClass('read-only');
  } else {
    $('.booking-invoice .checkbox input').attr('disabled', true);
    $('.booking-invoice').addClass('read-only');
  }
};

export default invoiceProject;

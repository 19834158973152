import $ from 'jquery';
import translate from 'i18n';

const workingDays = (startDate, finishDate, setDayText) => {
  var $publicHolidays = $('.booking-form').data().holidays;
  var date = new Date(startDate);
  var dayNum = 0;
  var weekendNum = 0;
  var holidayNum = 0;

  var endDate = new Date(finishDate);
  endDate.setDate(finishDate.getDate() + 1);

  const SUNDAY = 0;
  const SATURDAY = 6;

  while (date < endDate) {
    if (date.getDay() == SATURDAY || date.getDay() == SUNDAY) {
      weekendNum++;
    } else if ($publicHolidays.includes(date.toString().substr(4, 11))) {
      holidayNum++;
    } else {
      dayNum++;
    }

    date.setDate(date.getDate() + 1);
  }

  if (setDayText) {
    var dayText = dayNum + translate('booking.working_days');
    if (weekendNum > 0) {
      dayText +=
        translate('common.and') +
        weekendNum +
        translate('booking.weekend_days');
    }
    if (holidayNum > 0) {
      dayText +=
        translate('common.and') + holidayNum + translate('booking.holidays');
    }

    $('.booking-days')[0].innerText = dayText;
  }

  var dayCount = [];
  dayCount[0] = dayNum;
  dayCount[1] = weekendNum;
  dayCount[2] = holidayNum;

  return dayCount;
};

export default workingDays;

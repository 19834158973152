import $ from 'jquery';
import translate from 'i18n';

const openDialog = (name, title) => {
  $(name)
    .dialog({
      autoOpen: false,
      modal: true,
      width: 350,
      dialogClass: 'create-dialog',
      title: translate(title)
    })
    .dialog('open')
    .prev()
    .find('.ui-dialog-titlebar-close')
    .hide(); // Hide the standard close button;
};

export default openDialog;

import $ from 'jquery';

import translate from 'i18n';
import toggleCheckbox from 'toggle_checkbox';
import { ajaxCall } from 'ajax_call';

const ChurchHelper = () => {
  $(document).on('click', '.add-member', event => {
    newMemberDialog();
  });

  const newMemberDialog = () => {
    // TODO mobile responsive size
    var dialogWidth = 620;
    var title = translate('members.new');

    $('.new-member')
      .dialog({
        modal: true,
        show: 'show',
        width: dialogWidth,
        title: title,
        dialogClass: 'member-dialog'
      })
      .prev()
      .find('.ui-dialog-titlebar-close')
      .hide();
  };

  $(document).on('click', '.hide-member', event => {
    if ($('.new-member').length > 0) {
      $('.new-member').dialog('close');
    } else {
      console.log('Redirect me');
      Turbolinks.visit('/chrch/members');
    }
  });

  $(document).on('mouseenter', '.member-form input[type="submit"]', event => {
    var year = $('#chrch_member_date_of_birth_1i').val();
    var month = $('#chrch_member_date_of_birth_2i').val();
    var day = $('#chrch_member_date_of_birth_3i').val();
    var date = new Date(year, month, day, 0, 0, 0);

    $('#chrch_member_date_of_birth').val(date);
  });

  $(document).on('click', '.church-roll .checkbox', event => {
    event.preventDefault();

    var oldValue = $("input[type='hidden'][name='chrch_member[church_roll]']");
    parent = $(event.target).closest('.checkbox-holder');

    toggleCheckbox(parent, oldValue != '1');
  });

  $(document).on('click', '.remove-member', event => {
    event.preventDefault();

    console.log('Clicked remove');
    var params = {
      // Hacky but works, the class is attribute [0]
      remove_member_id: event.target.attributes[1].value
    };
    console.log(params);

    var url = $('form.edit_chrch_household')[0].action;
    console.log(url);

    ajaxCall('patch', url, params);
  });

  $(document).on('change', '.household-member-select select', event => {
    var params = {
      member_id: event.target.value
    };
    console.log(params);

    var url = $('form.edit_chrch_household')[0].action;
    console.log(url);

    ajaxCall('patch', url, params);
  });
};

export default ChurchHelper;

import $ from 'jquery';

const ProjectQuiz = () => {
  $(document).on('click', '.question .btn', event => {
    event.preventDefault();

    updateHighlighting(event.target);

    if ($(event.target).data('type') == 'screenshot') {
      selectScreenshot();
    }
  });

  const updateHighlighting = target => {
    var name = $(target).data('id');
    var info = '.' + name;

    if ($(target).data('id') == 'yes') {
      $(target).toggleClass('selected');
      $(info).toggleClass('hidden');
    } else {
      // Remove any selected highlighting from all buttons and information for this question
      $(target)
        .closest('.question')
        .find('.btn')
        .removeClass('selected');
      $(target)
        .closest('.question')
        .find('.answer')
        .addClass('hidden');

      // Mark the target selected
      $(target).addClass('selected');

      // Show the information for the selected target
      $(info).removeClass('hidden');
    }
  };

  const selectScreenshot = () => {
    $('.fields').addClass('hidden');

    var ids = $('.btn.selected')
      .map(function() {
        return $(this).data('id');
      })
      .get();

    var $screenshot = $('.none');

    if (ids.includes('external')) {
      if (ids.includes('regular')) {
        $screenshot = $('.both');
      } else {
        $screenshot = $('.customers');
      }
    } else if (ids.includes('regular')) {
      $screenshot = $('.tasks');
    }

    $screenshot.removeClass('hidden');
    $screenshot.fadeOut(300).fadeIn(300);
  };
};

export default ProjectQuiz;

import $ from 'jquery';
import {
  getWeekday,
  shortDateWithYear,
  dateSmartWeekends,
  validateDate
} from 'date_utils';
import {
  selectDefaultHours,
  setHoursForDay,
  populateHours
} from 'booking_tab_utils';
import translate from 'i18n';
import setWarn from 'setWarn';

const openBookingHoursModal = bookingId => {
  const title = translate('booking.book_hours');

  $('.time-form')
    .dialog({
      modal: true,
      show: 'show',
      width: 540,
      title: title,
      dialogClass: 'booking-dialog'
    })
    .prev()
    .find('.ui-dialog-titlebar-close')
    .hide();

  //Set the bookingId
  $('.time-form').find(
    "input#booked_time_booking_id[type='hidden']"
  )[0].value = bookingId;

  //Clear up old status
  $('.falert').hide();
  $('.warning').empty();
  $('.warning').hide();
  $('.booking').prop('disabled', false);

  var defaultStart = smartStart();
  var defaultFinish = smartFinish(defaultStart);

  //Set the date
  $('#booked_time_date_3i').val(defaultStart.getDate());
  $('#booked_time_date_2i').val(defaultStart.getMonth() + 1);
  $('#booked_time_date_1i').val(defaultStart.getFullYear());

  $('#booked_time_finish_3i').val(defaultFinish.getDate());
  $('#booked_time_finish_2i').val(defaultFinish.getMonth() + 1);
  $('#booked_time_finish_1i').val(defaultFinish.getFullYear());

  // Set the default hour listing
  populateHours(defaultStart, defaultFinish);

  const bookingForId = $('select#booking_user_id').val();
  var currentUserId = $('.current-user')[0].innerText;

  if (bookingForId === currentUserId) {
    $('.user-warn').addClass('hidden');
  } else {
    $('.user-warn').removeClass('hidden');
    var userName =
      $('select#booking_user_id')
        .find('option:selected')
        .text() || $('.booking-user-name').val();
    $('.user-for-warning').text(userName);
  }
};

const smartStart = () => {
  // Default start date is the booking start
  var bookingStart = validateDate('booking_start');
  var defaultStart = new Date(bookingStart);
  var finish = validateDate('booking_finish');

  var dateText = shortDateWithYear(defaultStart);
  const $rows = $('.booked-hours-rows').find('.row');

  $rows.each(function(index, row) {
    while (row.innerText.startsWith(dateText)) {
      defaultStart.setDate(defaultStart.getDate() + 1);
      dateText = shortDateWithYear(defaultStart);
    }
  });

  if (defaultStart < bookingStart) {
    $('.start-warn').show();
  }

  return dateSmartWeekends(defaultStart);
};

const smartFinish = defaultStart => {
  // Booking finish date
  var bookingFinish = validateDate('booking_finish');
  var defaultFinish = new Date(bookingFinish);

  var dateText = shortDateWithYear(defaultFinish);
  const $rows = $('.booked-hours-rows')
    .find('.row')
    .get()
    .reverse();

  $rows.forEach(function(row) {
    while (row.innerText.startsWith(dateText)) {
      defaultFinish.setDate(defaultFinish.getDate() - 1);
      dateText = shortDateWithYear(defaultFinish);
    }
  });

  defaultFinish = dateSmartWeekends(defaultFinish);

  // If we got further back than the start date, drop all those calculations and just use the start
  if (defaultStart > defaultFinish) {
    defaultFinish = new Date(defaultStart);
  }

  if (defaultFinish > bookingFinish) {
    $('.finish-warn').show();
  }

  return defaultFinish;
};

export default openBookingHoursModal;

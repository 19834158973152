import $ from 'jquery';

const CookieSignIn = () => {
  $(document).on('turbolinks:load', event => {
    // Only run this code on the signed out dashboard page
    if ($('.landing-page').length > 0) {
      var cookies = document.cookie;
      var subdomainCookie = cookies.split('_fflow_subdomain=')[1];

      if (subdomainCookie && subdomainCookie.length > 0) {
        var trimmedSubdomain = subdomainCookie.split(';')[0];

        $('.site-nav .sign-up').hide();

        var url = window.location.href.replace('www', trimmedSubdomain);
        if (url.indexOf(trimmedSubdomain) < 0) {
          url = url.replace('//', '//' + trimmedSubdomain + '.');
        }
        $('.cookie-sign-in a').attr('href', url);

        $('.cookie-sign-in').show();
      }
    }
  });
};

export default CookieSignIn;

import $ from 'jquery';

const CopyLink = () => {
  $(document).on('click', '.link-text, .fa-copy', event => {
    copyText();
  });

  const copyText = () => {
    var link = $('.link-text');
    link[0].select();
    document.execCommand('copy');
    window.getSelection().removeAllRanges();
    link
      .fadeOut(150)
      .fadeIn(150)
      .fadeOut(150)
      .fadeIn(150);
  };
};

export default CopyLink;

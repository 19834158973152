import $ from 'jquery';

import { ajaxCall } from 'ajax_call';
import { validateDate } from 'date_utils';

$(document).on(
  'change',
  '#setting_vacation_year_start_date_3i, #setting_vacation_year_start_date_2i',
  event => {
    event.stopImmediatePropagation();

    const date = validateDate('setting_vacation_year_start_date', false);

    if (date) {
      const method = 'put';
      const url = '/settings';
      var currentPath = window.location.href;

      const authenticity_token = $(
        'input[name="authenticity_token"]',
        event.target.closest('form')
      ).val();

      let params = {
        authenticity_token: authenticity_token,
        active: currentPath.split('active=')[1],

        setting: {
          'vacation_year_start_date(3i)': date.getDate(),
          'vacation_year_start_date(2i)': date.getMonth() + 1
        }
      };

      ajaxCall(method, url, params);
    }
  }
);

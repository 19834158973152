import $ from 'jquery';
import translate from 'i18n';

const setError = (errorText, alertField, fieldName) => {
  if (alertField === undefined) {
    alertField = $('.falert');
  }

  if (alertField.length > 0) {
    alertField.each(function(index) {
      alertField[index].textContent = translate(errorText, fieldName);
    });
    alertField.show();
  }

  $('input[type="submit"]').prop('disabled', true);

  $('.warning').hide();
};

const toSentence = array => {
  if (array && array.length > 0) {
    if (array.length == 1) {
      return array[0];
    } else if (array.length == 2) {
      return array[0] + ' and ' + array[1];
    } else {
      let string = array.slice(0, array.length - 2).join(', ');
      string += ', ';
      string += array.slice(-2, array.length - 1);
      string += ' and ' + array.slice(-1);
      return string;
    }
  }
  return '';
};

const clearErrorAndWarn = () => {
  $('.falert').empty();
  $('.falert').hide();
  $('.warning').empty();
  $('.warning').hide();

  $('input[type="submit"]').prop('disabled', false);
};

export { setError, clearErrorAndWarn, toSentence };

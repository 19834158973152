import $ from 'jquery';
import translate from 'i18n';
import toggleCheckbox from 'toggle_checkbox';
import getBookingTaskInfo from 'get_booking_task_info';

const getBookingTasks = (type, id, previousTaskId, updateTaskValues) => {
  var url = '/task_data?' + type + '=' + id;

  // Clear the previous option set, then add a blank option at the top
  $('select#booking_task_id').empty();
  var $option = document.createElement('option');
  $('select#booking_task_id').append($option);

  // If there's no project or service id
  // insert an option to create a new task from here
  if (id === undefined || id === 0 || id === '') {
    $option = document.createElement('option');
    $option.text = translate('task.create');
    $option.value = 0;
    $('select#booking_task_id').append($option);
  }

  $.ajax({
    url: url,
    dataType: 'json',
    success: function(response) {
      if ($('.booking-form').length > 0) {
        updateBookingForm(response, previousTaskId, updateTaskValues);
      } else if ($('.project-form').length > 0) {
        updateProjectForm(response);
      }
    }
  });
};

const updateProjectForm = response => {
  $(response).each(function(index, element) {
    const checkboxHolder = $(`.tasks input#${element}`).closest(
      '.checkbox-holder'
    )[0];
    toggleCheckbox(checkboxHolder, true);
  });
};

const updateBookingForm = (response, previousTaskId, updateTaskValues) => {
  var $option;

  $(response).each(function(index, element) {
    $option = document.createElement('option');
    $option.text = element[0];
    $option.value = element[1];
    $('select#booking_task_id').append($option);
  });

  if (previousTaskId) {
    // Always update the task id
    $('#booking_task_id').val(previousTaskId);
    // The user might have set their own values in fields, don't automatically overwrite them
    if (updateTaskValues) {
      getBookingTaskInfo(previousTaskId);
    }
  }
};

export default getBookingTasks;

import $ from 'jquery';

const AdminPayments = () => {
  $(document).on('change', '.payment-account select', event => {
    var url = '/admins/payments?account=' + $(event.target).val();
    Turbolinks.visit(url);
  });
};

export default AdminPayments;
